<script lang="ts" setup>
import type { BaseImageFragment, Category } from "@/types/graphql";

const props = defineProps<{
  headline: string;
  subheadline?: string;
  image: BaseImageFragment | undefined;
  url?: string;
  categories?: Category | null;
  type: "referenz" | "blog";
  imgsizes?: string | null;
}>();
</script>
<template>
  <nuxt-link
    :to="props.url"
    class="link-card grid"
    :class="{
      'hover:bg-light-black hover:text-integer-white': type === 'referenz',
      'hover:bg-light-gray': type === 'blog',
    }"
  >
    <div
      class="link-card-image image aspect-square object-contain bg-integer-black overflow-hidden"
    >
      <base-image
        v-if="props.image"
        v-bind="props.image"
        class="object-cover w-full h-full"
        :sizes="props.imgsizes ?? '20rem'"
      />
    </div>
    <div class="link-card-content p-5">
      <div class="flex flex-wrap gap-2">
        <span v-if="categories">
          {{ categories.display_name }}
        </span>
        <p v-if="subheadline" class="link-card-subheadline text-body">
          {{ `${props.subheadline}` }}
        </p>
      </div>
      <h2
        :class="{
          'mt-5.2': subheadline,
          'line-clamp-[2] sm:line-clamp-[3]': type === 'blog',
        }"
        class="link-card-headline text-normal mt-5.2"
      >
        {{ props.headline }}
      </h2>
    </div>
  </nuxt-link>
</template>
<style scoped>
.link-card {
  grid-template-columns: 1fr 2fr;
  transition: background-color 300ms ease-out, color 300ms ease-out;
}
</style>

